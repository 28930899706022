import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingState = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress size={50} />
    </Backdrop>
  );
};

export default LoadingState;
