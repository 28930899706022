import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AuthContext from "../../Helpers/AuthContext";
import {
  CustomList,
  MyListItemIcon,
  MyListItemText,
} from "../StyledComponents";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";

const ListItem = ({
  pages,
  divider = true,
  onlyAdmin = false,
  noParent = false,
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  const {
    userData: { userroles, usertype },
  } = useContext(AuthContext);

  const [validPages, setValidPages] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const allPages = noParent ? pages : pages.pages;

    const haspage = allPages.find((p) => p.path === location.pathname);

    if (haspage) setOpen(true);

    if (usertype === "Administrateur") {
      setValidPages(allPages);
    } else {
      if (onlyAdmin) return;

      const newPages = allPages.filter((page) => {
        if (page.key !== undefined && userroles[page.key] !== "on") {
          return null;
        }

        return page;
      });

      setValidPages(newPages);
    }
  }, [location.pathname, noParent, pages, usertype, userroles, onlyAdmin]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const ListItem = ({ page, child = true }) => {
    const { title, path, icon } = page;
    return (
      <CustomList
        sx={{ pl: child ? 3 : 2 }}
        button
        className={`${location.pathname === path ? "activemenu" : ""}`}
        onClick={() => navigate(path)}
      >
        <MyListItemIcon>{icon}</MyListItemIcon>
        <MyListItemText primary={title} />
      </CustomList>
    );
  };

  return noParent ? (
    validPages.map((page) => (
      <ListItem key={page.id} page={page} child={false} />
    ))
  ) : (
    <>
      <CustomList onClick={handleToggle}>
        <MyListItemIcon>{pages.icon}</MyListItemIcon>
        <MyListItemText primary={pages.title} />
        {open ? (
          <ExpandLess sx={{ color: "white" }} />
        ) : (
          <ExpandMore sx={{ color: "white" }} />
        )}
      </CustomList>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {validPages.map((page) => (
            <ListItem key={page.id} page={page} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default ListItem;
