import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SpeedIcon from "@mui/icons-material/Speed";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import HandshakeIcon from "@mui/icons-material/Handshake";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";

export const homePage = [
    {
        id: 1,
        title: "Acceuil",
        icon: <SpeedIcon/>,
        path: "/",
    },
];

export const reportPage = {
    title: "Rapports",
    icon: <PlaylistAddCheckIcon/>,
    pages:
        [
            {
                id: 1,
                title: "Ajout Rapport",
                icon: <AddTaskIcon/>,
                path: "/addreport",
                key: "addpreport",
            },
            {
                id: 2,
                title: "Gestion Rapports",
                icon: <PlaylistAddCheckIcon/>,
                path: "/managereports",
            },
        ]
}
export const projectPage = {
    title: "Projets",
    icon: <ListAltIcon/>,
    pages: [
        {
            id: 1,
            title: "Ajout Projet",
            icon: <AddCircleOutlineIcon/>,
            path: "/addproject",
            key: "addproject",
        },
        {
            id: 2,
            title: "Gestion Projets",
            icon: <ListAltIcon/>,
            path: "/manageprojects",
        },
    ]
};
export const usersPage = {
    title: "Utilisateurs",
    icon: <PeopleAltIcon/>,
    pages: [
        {
            id: 1,
            title: "Ajout Utilisateur",
            icon: <GroupAddIcon/>,
            path: "/adduser",
        },
        {
            id: 2,
            title: "Gestion Utilisateurs",
            icon: <PeopleAltIcon/>,
            path: "/manageusers",
        },
    ]
};

export const partnerPage = {
    title: "Partenaires",
    icon: <HandshakeIcon/>,
    pages: [
        {
            id: 1,
            title: "Ajout Partenaire",
            icon: <VolunteerActivismIcon/>,
            path: "/addpartner",
            key: "addpartner",
        },
        {
            id: 2,
            title: "Gestion Partenaires",
            icon: <HandshakeIcon/>,
            path: "/managepartners",
        },
    ]
};
export const profilePage = [
    {
        id: 1,
        title: "Mon Profile",
        icon: <AccountCircleIcon/>,
        path: "/profile",
    },
];
export const locationPage = {
    title: "Administrateur",
    icon: <SettingsIcon/>,
    pages: [
        {
            id: 1,
            title: "Gestion Localisation",
            icon: <LocationOnIcon/>,
            path: "/localisation",
        },
        {
            id: 2,
            title: "Gestion Droits",
            icon: <VerifiedUserIcon/>,
            path: "/roles",
        },
        {
            id: 3,
            title: "Gestion Type Rapport",
            icon: <FlagIcon/>,
            path: "/reporttype",
        },
    ]
};

export const homeCards = [
    {
        id: 1,
        title: "Nombres de projets",
        type: "number",
        link: "projects",
        data: "0",
        color: "#cf4447",
        icon: <ListAltIcon/>,
    },
    {
        id: 2,
        title: "Nombres de rapports",
        type: "number",
        data: "0",
        link: "reports",
        color: "#25b7a6",
        icon: <PlaylistAddCheckIcon/>,
    },
    {
        id: 4,
        title: "Nombres de partenaires",
        type: "number",
        data: "0",
        link: "partners",
        color: "#2278cf",
        icon: <HandshakeIcon/>,
    },
    {
        id: 3,
        title: "Nombres d'utilisateurs",
        type: "number",
        data: "0",
        link: "users",
        color: "#fdaf36",
        icon: <PeopleAltIcon/>,
    },
    {
        id: 5,
        title: "Gestion de projets",
        type: "links",
        link: [
            {id: 101, title: "Ajout Projet", url: "/addproject"},
            {id: 111, title: "Gestion Projets", url: "/manageprojects"},
        ],
        color: "#cf4447",
        icon: <ListAltIcon/>,
        key: "addproject",
    },
    {
        id: 6,
        title: "Gestion de rapports",
        type: "links",
        link: [
            {id: 122, title: "Ajout Rapport", url: "/addreport"},
            {id: 133, title: "Gestion Rapports", url: "/managereports"},
        ],
        color: "#25b7a6",
        icon: <PlaylistAddCheckIcon/>,
        key: "addpreport",
    },
    {
        id: 8,
        title: "Gestion de partenaires",
        type: "links",
        link: [
            {id: 124, title: "Ajout Partenaire", url: "/addpartner"},
            {id: 134, title: "Gestion Partenaires", url: "/managepartners"},
        ],
        color: "#2278cf",
        icon: <HandshakeIcon/>,
        key: "addpartner",
    },
    {
        id: 7,
        title: "Gestion d'utilisateurs",
        type: "links",
        link: [
            {id: 105, title: "Ajout Utilisateur", url: "/adduser"},
            {id: 115, title: "Gestion Utilisateurs", url: "/manageusers"},
        ],
        color: "#fdaf36",
        icon: <PeopleAltIcon/>,
        key: "users",
    },
];
