import { createContext, useState, useEffect } from "react";
import authAPI from "./authApi";

const AuthContext = createContext({
  isLogged: false,
  isLoading: true,
  userData: {},
  setIsLogged: () => {},
  setUserData: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const login = authAPI.checkLogin();
    setIsLogged(login);
    if (login) {
      setUserData(authAPI.getUserData());
    }
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLogged, isLoading, setIsLogged, userData, setUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
