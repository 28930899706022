import {useState, useEffect} from 'react';

const useSidebar = () => {
    const [open, setOpen,] = useState(false);

    useEffect(() => {
        const sidebar = window.localStorage.getItem('sidebar');
        if (sidebar && sidebar === 'open') setOpen(true);
    }, []);

    const toggleSidebar = (value) => {
        if (value) window.localStorage.setItem('sidebar', 'open')
        else window.localStorage.setItem('sidebar', 'close')

        setOpen(value);
    }

    return [open, toggleSidebar];
};

export default useSidebar;