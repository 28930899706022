import {
    AppBar,
    styled,
    Drawer,
    ListItem,
    ListItemText,
    ListItemIcon,
    Card,
    Typography,
    Box,
    Grid,
    TextField,
} from "@mui/material";
import {drawerWidth} from "../Helpers/constants";

const customDrawerStyle = {
    backgroundColor: "#111826",
};

const openedMixin = (theme) => ({
    ...customDrawerStyle,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    ...customDrawerStyle,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const ErrorComponent = styled("p")`
  color: #dd0c0c;
  text-align: center;
  font-size: 14px;
  margin: 8px 0;
`;

export const Image = styled("img")`
  width: 50%;
  margin-bottom: 30px;
`;
export const ImageAppBar = styled("img")`
  height: 35px;
  vertical-align: middle;
`;

export const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export const MyAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    boxShadow: "0px 1px 4px rgba(100, 116, 139, 0.12)",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const MyDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export const CustomList = styled(ListItem)`
  border-radius: 12px;
  transition: 0.4s all ease;
  .MuiListItemIcon-root {
    min-width: 49px;
  }
  .MuiTypography-root {
    font-size: 14px;
  }
  &:hover {
    background-color: #242a37;
  }
  &.activemenu {
    margin: 4px 0;
    background-color: #242a37;
    .MuiSvgIcon-root,
    .MuiTypography-root {
      color: #23b882;
    }
  }
`;

export const MyListItemText = styled(ListItemText)`
  span {
    color: #9ca3ae;
    font-weight: 600;
  }
`;

export const MyListItemIcon = styled(ListItemIcon)`
  svg {
    color: #9ca3ae;
  }
`;

export const MyHomeCard = styled(Card)`
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  border-radius: 10px;
  padding: 15px;
  position: relative;
  flex: 1;
  &.loading {
    opacity: 0.6;
  }
`;

export const MyTitle = styled(Typography)`
  font-size: 30px;
  font-weight: 600;
  margin: 30px 0;
  flex: 1;
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

export const FormBox = styled(Box)`
  width: 100%;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  background-color: white;
  border-radius: 10px;
  padding: 0;
  margin-bottom: 24px;
`;

export const ProfileContainer = styled(Box)`
  width: 100%;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const GridForm = styled(Grid)`
  padding: 15px;
`;

export const SearchBoxContainer = styled(Box)`
  background-color: white;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 24px;
`;

export const MyTextField = styled(TextField)`
  input,
  .MuiSelect-select,
  textarea {
    &.Mui-disabled {
      -webkit-text-fill-color: black;
      color: black;
      font-weight: 700;
    }
  }
`;
