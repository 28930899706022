import {Divider, IconButton, List} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {DrawerHeader, MyDrawer} from "../StyledComponents";
import {homePage, locationPage, partnerPage, profilePage, projectPage, reportPage, usersPage,} from "../../Helpers/pages";

import ListItem from "./ListItem";

const SideBar = ({open, setOpen}) => {
    return (
        <MyDrawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton color="primary" onClick={() => setOpen(false)}>
                    <ChevronLeftIcon/>
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                <ListItem noParent divider={false} pages={homePage}/>
                <ListItem pages={projectPage}/>
                <ListItem pages={reportPage}/>
                <ListItem pages={partnerPage}/>
                <ListItem onlyAdmin pages={usersPage}/>
                <ListItem onlyAdmin pages={locationPage}/>
                <ListItem noParent pages={profilePage}/>
            </List>
        </MyDrawer>
    );
};

export default SideBar;
