import {useContext, lazy, Suspense} from "react";
import Box from "@mui/material/Box";
import {Routes, Route} from "react-router-dom";

import AuthContext from "./Helpers/AuthContext";

import AppBar from "./Components/Header/AppBar";
import SideBar from "./Components/Header/SideBar";
import {DrawerHeader} from "./Components/StyledComponents";
import LoadingState from "./Components/LoadingState";
import useSidebar from "./Hooks/useSidebar";

const Home = lazy(() => import("./Screen/Home"));
const SignIn = lazy(() => import("./Screen/SignIn"));
const NotFound = lazy(() => import("./Screen/NotFound"));

const AddProject = lazy(() => import("./Screen/AddProject"));
const ManageProjects = lazy(() => import("./Screen/ManageProjects"));

const AddReport = lazy(() => import("./Screen/AddReport"));
const ManageReports = lazy(() => import("./Screen/ManageReports"));

const AddPartner = lazy(() => import("./Screen/AddPartner"));
const ManagePartners = lazy(() => import("./Screen/ManagePartners"));

const AddUser = lazy(() => import("./Screen/AddUser"));
const ManageUsers = lazy(() => import("./Screen/ManageUsers"));

const Localisation = lazy(() => import("./Screen/Localisation"));
const TypeUsers = lazy(() => import("./Screen/TypeUsers"));
const ReportType = lazy(() => import("./Screen/ReportType"));

const Profile = lazy(() => import("./Screen/Profile"));

export default function MiniDrawer() {
    const {isLogged, userData, isLoading} = useContext(AuthContext);
    const userRoles = userData.userroles;
    const userType = userData.usertype;

    const [sidebar, toggleSidebar] = useSidebar();

    if (isLoading) return null;

    return (
        <Box sx={{display: "flex"}}>
            {isLogged ? (
                <>
                    <AppBar open={sidebar} setOpen={toggleSidebar}/>
                    <SideBar open={sidebar} setOpen={toggleSidebar}/>

                    <Box component="main" sx={{flexGrow: 1, p: 3}}>
                        <DrawerHeader/>
                        <Suspense fallback={<LoadingState open/>}>
                            <Routes>
                                <Route exact path="/" element={<Home/>}/>

                                {userRoles.addproject === "on" && (
                                    <Route path="addproject" element={<AddProject/>}/>
                                )}
                                <Route path="manageprojects" element={<ManageProjects/>}/>

                                {userRoles.addpreport === "on" && (
                                    <Route path="addreport" element={<AddReport/>}/>
                                )}
                                <Route path="managereports" element={<ManageReports/>}/>

                                {userRoles.addpartner === "on" && (
                                    <Route path="addpartner" element={<AddPartner/>}/>
                                )}
                                <Route path="managepartners" element={<ManagePartners/>}/>

                                <Route path="profile" element={<Profile/>}/>

                                {userType === "Administrateur" && (
                                    <>
                                        <Route path="adduser" element={<AddUser/>}/>
                                        <Route path="manageusers" element={<ManageUsers/>}/>
                                        <Route path="localisation" element={<Localisation/>}/>
                                        <Route path="roles" element={<TypeUsers/>}/>
                                        <Route path="reporttype" element={<ReportType/>}/>
                                    </>
                                )}

                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </Suspense>
                    </Box>
                </>
            ) : (
                <Suspense fallback={<LoadingState open/>}>
                    <SignIn/>
                </Suspense>
            )}
        </Box>
    );
}

// cloudriff
