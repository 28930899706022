import {useContext, useState} from "react";
import {Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {useNavigate} from "react-router-dom";

import AuthContext from "../../Helpers/AuthContext";
import authAPI from "../../Helpers/authApi";
import {ImageAppBar, MyAppBar} from "../StyledComponents";
import logo from "../../Assets/logo.png";

const AppBar = ({open, setOpen}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const {setIsLogged} = useContext(AuthContext);

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const navigateProfile = () => {
        setAnchorEl(null);
        navigate("/profile");
    };

    const handleLogout = () => {
        setAnchorEl(null);
        authAPI.logout();
        setIsLogged(false);
        navigate("/");
    };

    return (
        <MyAppBar elevation={0} position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    edge="start"
                    sx={{
                        marginRight: "36px",
                        ...(open && {display: "none"}),
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <Box component="div" sx={{flexGrow: 1}}>
                    <ImageAppBar alt="logo YPA" src={logo}/>
                </Box>

                <div>
                    <IconButton color="primary" size="large" onClick={handleMenu}>
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={navigateProfile}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Mon Profile</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <PowerSettingsNewIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Déconnexion</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </MyAppBar>
    );
};

export default AppBar;
